@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');
  :root {
    --shop-theme1-primary-color:#f47b63;
  }
.blue-theme{    
    --shop-theme1-primary-color:#6863f4;      
  }
  .morecontent span {
      display: none;
  }
  .morelink {
      display: block;
  }
  .theme1 .section-title h2{
      font-size:2.8rem;
      color:#373737;
      padding-bottom: 15px;
      margin-bottom: 30px;
  }
  
.theme1 .section-title{
      position:relative;
      text-align: left;
  }
  
  .navbar-reduce{
      transition: all .5s ease-in-out;
      padding-top: 19px;
      padding-bottom: 19px;
      position:fixed;
  }
  
  .nav-pills-a.nav-pills .nav-link {
    color: #000000;
    position: relative;
    font-weight: 600;
  }
  
  .nav-pills-a.nav-pills .nav-link.active {
    background-color: transparent;
  }
  
  .nav-pills-a.nav-pills .nav-link.active:after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -1px;
    width: 100%;
    height: 2px;
    background-color: #2eca6a;
    z-index: 2;
  }
  
  .a {
    color: #2eca6a;
    font-size: 1.3rem;
  }
  
  .theme1 section{padding:100px 45px;}
  
  /* Shortcode */
  
  .nowrap { white-space:nowrap;}
  .no-float { float:none;}
  .font-orange-theme { font-size:23px !important; color:var(--shop-theme1-primary-color); line-height:1;}
  .mr-10 { margin-right:10px;}
  .slimScrollDiv { bottom:23px;}
  .xs-only { display:none;}
  .slick-slide, .price-tab-left .nav-tabs .nav-link { outline:none;}
  
  
  
  
  section.about-section{padding:100px 45px;text-align: left;}
  section.section-two{padding:0px 0px;}
  /* Back to top buttosn */
  
  .back-to-top {
    position: fixed;
    display: none;
    background: var(--shop-theme1-primary-color);
    color: #fff;
    width: 44px;
    height: 44px;
    text-align: center;
    line-height: 1;
    font-size: 16px;
    border-radius: 50%;
    right: 15px;
    bottom: 15px;
    transition: background 0.5s;
    z-index: 11;
  }
  
  .back-to-top i {
    padding-top: 12px;
    color: #fff;
  }
  /* Prelaoders */
  
  #preloader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;
    overflow: hidden;
    background: #fff;
  }
  
  #preloader:before {
    content: "";
    position: fixed;
    top: calc(50% - 30px);
    left: calc(50% - 30px);
    border: 6px solid #f2f2f2;
    border-top: 6px solid var(--shop-theme1-primary-color);
    border-radius: 50%;
    width: 60px;
    height: 60px;
    -webkit-animation: animate-preloader 1s linear infinite;
    animation: animate-preloader 1s linear infinite;
  }
  
  @-webkit-keyframes animate-preloader {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
  
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  
  @keyframes animate-preloader {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
  
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  /* navbar */
  .text-brand img{
      padding-right:5px;
  }
  .navbar-default {
    transition: all .5s ease-in-out;
    background-color: #ffffff;
    padding: 10px 45px 10px 45px;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }
  
  .navbar-default .nav-search {
    color: #000000;
    font-size: 1.5rem;
  }
  
  .navbar-default.navbar-reduce {
    box-shadow: 1px 2px 15px rgba(100, 100, 100, 0.3);
  }
  
  .navbar-default.navbar-trans,
  .navbar-default.navbar-reduce {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }
  
  .themenavbar {
      flex-grow: initial;
  }
  
  .navbar .themenavbar ul li.nav-item{
    position: relative;
    padding-right: 35px;
    padding-bottom: 0px;
    margin-left: 0;
  }
  
  .navbar .themenavbar ul li.nav-item a:hover{
      color:#09b2dc;
  }
  
  .navbar .themenavbar ul li.active a {
    color: var(--shop-theme1-primary-color);
}
  
  .navbar .themenavbar ul li.nav-item:last-child{padding-right:0px;}
  
  .navbar-default.navbar-trans .nav-link,
  .navbar-default.navbar-reduce .nav-link {
    color: #000000;
    font-weight: 500;
    letter-spacing: 0.030em;
    transition: all 0.1s ease-in-out;
    position: relative;
    padding-left: 0;
    padding-right: 0;
  }
  
  /*.navbar-default.navbar-trans .nav-link:before,
  .navbar-default.navbar-reduce .nav-link:before {
    content: '';
    position: absolute;
    bottom: 5px;
    left: 0;
    width: 100%;
    height: 2px;
    z-index: 0;
    background-color: #2eca6a;
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transform-origin: right;
    transform-origin: right;
    transition: opacity .2s ease-out 0.3s, -webkit-transform .2s ease-out;
    transition: transform .2s ease-out, opacity .2s ease-out 0.3s;
    transition: transform .2s ease-out, opacity .2s ease-out 0.3s, -webkit-transform .2s ease-out;
  }*/
  
  
  .navbar-default.navbar-trans .nav-link:hover:before,
  .navbar-default.navbar-reduce .nav-link:hover:before {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    -webkit-transform-origin: left;
    transform-origin: left;
  }
  
  .navbar-default.navbar-trans .show > .nav-link:before,
  .navbar-default.navbar-trans .active > .nav-link:before,
  .navbar-default.navbar-trans .nav-link.show:before,
  .navbar-default.navbar-trans .nav-link.active:before,
  .navbar-default.navbar-reduce .show > .nav-link:before,
  .navbar-default.navbar-reduce .active > .nav-link:before,
  .navbar-default.navbar-reduce .nav-link.show:before,
  .navbar-default.navbar-reduce .nav-link.active:before {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }
  
  /*.navbar-default.navbar-trans .nav-link:before {
    background-color: #2eca6a;
  }*/
  
  .navbar-default.navbar-trans .nav-link:hover {
    color: #000000;
  }
  
  .navbar-default.navbar-reduce {
    transition: all .5s ease-in-out;
    padding-top: 15px;
    padding-bottom: 12px;
    z-index: 9;
    width: 100%;
    top: 0px;
  }
  
  .navbar-default.navbar-reduce .nav-link {
    color: #000000;
  }
  
  .navbar-default.navbar-reduce .nav-link:before {
    background-color: #2eca6a;
  }
  
  .navbar-default.navbar-reduce .nav-link:hover {
    color: #000000;
  }
  
  .navbar-default.navbar-reduce .show > .nav-link,
  .navbar-default.navbar-reduce .active > .nav-link,
  .navbar-default.navbar-reduce .nav-link.show,
  .navbar-default.navbar-reduce .nav-link.active {
    color: #000000;
  }
  
  .navbar-default.navbar-reduce .navbar-brand {
    color: #000000;
  }
  
  .navbar-default .dropdown .dropdown-menu {
    border-top: 0;
    border-left: 4px solid #2eca6a;
    border-right: 0;
    border-bottom: 0;
    -webkit-transform: translate3d(0px, -40px, 0px);
    transform: translate3d(0px, -40px, 0px);
    opacity: 0;
    filter: alpha(opacity=0);
    visibility: hidden;
    transition: all 0.5s cubic-bezier(0.3, 0.65, 0.355, 1) 0s, opacity 0.31s ease 0s, height 0s linear 0.36s;
    margin: 0;
    border-radius: 0;
    padding: 12px 0;
  }
  
  .navbar-default .dropdown .dropdown-menu .dropdown-item {
    padding: 12px 18px;
    transition: all 500ms ease;
    font-weight: 600;
    min-width: 220px;
  }
  
  .navbar-default .dropdown .dropdown-menu .dropdown-item:hover {
    background-color: #ffffff;
    color: #2eca6a;
    transition: all 500ms ease;
  }
  
  .navbar-default .dropdown .dropdown-menu .dropdown-item.active {
    background-color: #ffffff;
    color: #2eca6a;
  }
  
  .navbar-default .dropdown:hover .dropdown-menu {
    -webkit-transform: translate3d(0px, 0px, 0px);
    transform: translate3d(0px, 0px, 0px);
    visibility: visible;
    opacity: 1;
    filter: alpha(opacity=1);
  }
  
  /* Hamburger Navbar */
  
  .navbar-toggler {
    position: relative;
  }
  
  .navbar-toggler:focus,
  .navbar-toggler:active {
    outline: 0;
  }
  
  .navbar-toggler span {
    display: block;
    background-color: #000000;
    height: 3px;
    width: 25px;
    margin-top: 4px;
    margin-bottom: 4px;
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    left: 0;
    opacity: 1;
  }
  
  .navbar-toggler span:nth-child(1),
  .navbar-toggler span:nth-child(3) {
    transition: -webkit-transform .35s ease-in-out;
    transition: transform .35s ease-in-out;
    transition: transform .35s ease-in-out, -webkit-transform .35s ease-in-out;
  }
  
  .navbar-toggler:not(.collapsed) span:nth-child(1) {
    position: absolute;
    left: 12px;
    top: 10px;
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg);
    opacity: 0.9;
  }
  
  .navbar-toggler:not(.collapsed) span:nth-child(2) {
    height: 12px;
    visibility: hidden;
    background-color: transparent;
  }
  
  .navbar-toggler:not(.collapsed) span:nth-child(3) {
    position: absolute;
    left: 12px;
    top: 10px;
    -webkit-transform: rotate(-135deg);
    transform: rotate(-135deg);
    opacity: 0.9;
  }
  .offsec-hedaer{
     width: 170px;
     background: var(--shop-theme1-primary-color);
     border-radius: 50%;
     height: 170px;
     /* line-height: 190px; */
     position: absolute;
     right: 130px;
     bottom: -60px;
     text-align:center;
  }
  .offsec-hedaer h4{
      color:#fff;
      padding-top:50px;
  }
  .offsec-hedaer p, .offsec-hedaer a{
      color:#fff;
      font-size:12px;
  }
  .offsec-hedaer i{font-size:14px;padding-left:3px;}
  .current-status {
      display: block;
      border-radius: 50%;
      cursor: pointer;
      box-shadow: 0 0 0 rgba(218,31,69, 0.4);
      animation: current-status 1.5s infinite;
      z-index:1;
  }
  .current-status:hover {
      animation: none;
  }
  @-webkit-keyframes current-status {
     0% {
            box-shadow: 0 0 0 0px rgba(218,31,69, 0.5);
       }
       100% {
            box-shadow: 0 0 0 15px rgba(218,31,69, 0);
       }
  }
  /* header area end */
  
  /* sectio-banner area start */
  section.section-banner{padding:0px;}
  .section-banner{
      position: relative;
  }
  .custom-search-laundry{
      height: auto;
      border-radius: 15px;
      position: absolute;
      width:25%;
      left: 60px;
      top: 15px;
      z-index: 1;
  }
  .custom-search-laundry .location-search{position:relative;background: var(--shop-theme1-primary-color);border-radius: 10px;}
  div.fancy-select {
      font-weight: bold;
      text-transform: uppercase;
      font-size: 13px;
      color: #46565D;
      width: 30%;
      position: absolute;
      top: 0px;
  }
  
  .location-search input{height: 60px;border: 0px;border-radius: 10px;line-height: 60px;padding-left: 70px;font-size:13px;font-weight:600;color: #fff;background: var(--shop-theme1-primary-color);}
  
  .location-search input:focus{background:var(--shop-theme1-primary-color);border:0px;box-shadow: 0px 11px 12px 0px rgba(0,0,0,0.25);color:#fff;}
  
  .location-search input.form-control::-webkit-input-placeholder { color: white; }
  .location-search input.form-control:-moz-placeholder { color: white; }
  .location-search input.form-control::-moz-placeholder { color: white; }
  .location-search input.form-control:-ms-input-placeholder { color: white; }
  .location-search input.form-control::-ms-input-placeholder { color: white; }
  .location-search img{
      position:absolute;
      top:15px;
      left:15px;
  }
  div.fancy-select.disabled {
      opacity: 0.5;
  }
  
  div.fancy-select select:focus + div.trigger.open {
      box-shadow: none;
  }
  
  div.fancy-select div.trigger {
      cursor: pointer;
      padding: 8px 20px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      position: relative;
      background: transparent;
      border: 0;
      border-top-color: currentcolor;
      border-top-color: #A5B2CB;
      color: #fff;
      box-shadow: 0;
      transition: all 240ms ease-out;
      -webkit-transition: all 240ms ease-out;
      -moz-transition: all 240ms ease-out;
      -ms-transition: all 240ms ease-out;
      -o-transition: all 240ms ease-out;
      border-radius: 10px;
      height: 60px;
      line-height: 45px;
  }
  
  div.fancy-select div.trigger:after {
      content: "";
      display: block;
      position: absolute;
      width: 0;
      height: 0;
      border: 5px solid transparent;
      border-top-color: #fff;
      top: 27px;
      right: 9px;
      color:#fff;
  }
  
  div.fancy-select div.trigger.open {
      background: transparent;
      border: 0px;
      color: #fff;
      box-shadow: none;
  }
  
  div.fancy-select div.trigger.open:after {
      border-top-color: #fff;
  }
  
  div.fancy-select ul.options {
      list-style: none;
      margin: 0;
      position: absolute;
      top: 40px;
      left: 0;
      visibility: hidden;
      opacity: 0;
      z-index: 50;
      max-height: 200px;
      overflow: auto;
      background: #fff;
      border-radius: 4px;
      width:100%;
      border-top: 1px solid #7DD8D2;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
      transition: opacity 300ms ease-out, top 300ms ease-out, visibility 300ms ease-out;
      -webkit-transition: opacity 300ms ease-out, top 300ms ease-out, visibility 300ms ease-out;
      -moz-transition: opacity 300ms ease-out, top 300ms ease-out, visibility 300ms ease-out;
      -ms-transition: opacity 300ms ease-out, top 300ms ease-out, visibility 300ms ease-out;
      -o-transition: opacity 300ms ease-out, top 300ms ease-out, visibility 300ms ease-out;
      padding:0px;
      margin:0px;
      font-weight:100;
  }
  
  div.fancy-select ul.options.open {
      visibility: visible;
      top: 50px;
      opacity: 1;
      transition: opacity 300ms ease-out, top 300ms ease-out;
      -webkit-transition: opacity 300ms ease-out, top 300ms ease-out;
      -moz-transition: opacity 300ms ease-out, top 300ms ease-out;
      -ms-transition: opacity 300ms ease-out, top 300ms ease-out;
      -o-transition: opacity 300ms ease-out, top 300ms ease-out;
  }
  
  div.fancy-select ul.options.overflowing {
      top: auto;
      bottom: 40px;
  
      transition: opacity 300ms ease-out, bottom 300ms ease-out, visibility 300ms ease-out;
      -webkit-transition: opacity 300ms ease-out, bottom 300ms ease-out, visibility 300ms ease-out;
      -moz-transition: opacity 300ms ease-out, bottom 300ms ease-out, visibility 300ms ease-out;
      -ms-transition: opacity 300ms ease-out, bottom 300ms ease-out, visibility 300ms ease-out;
      -o-transition: opacity 300ms ease-out, bottom 300ms ease-out, visibility 300ms ease-out;
  }
  
  div.fancy-select ul.options.overflowing.open {
      top: auto;
      bottom: 50px;
  
      transition: opacity 300ms ease-out, bottom 300ms ease-out;
      -webkit-transition: opacity 300ms ease-out, bottom 300ms ease-out;
      -moz-transition: opacity 300ms ease-out, bottom 300ms ease-out;
      -ms-transition: opacity 300ms ease-out, bottom 300ms ease-out;
      -o-transition: opacity 300ms ease-out, bottom 300ms ease-out;
  }
  
  div.fancy-select ul.options li {
      padding: 8px 12px;
      color: #2B8686;
      cursor: pointer;
      white-space: nowrap;
      transition: all 150ms ease-out;
      -webkit-transition: all 150ms ease-out;
      -moz-transition: all 150ms ease-out;
      -ms-transition: all 150ms ease-out;
      -o-transition: all 150ms ease-out;
  }
  
  div.fancy-select ul.options li.selected {
      background: #00b0db;
      color: #fff;
  }
  ul.options, .product-selection ul  {
      padding:0px;
      margin:0px;
  }
  
  .product-selection ul a li{
      display: block;
      padding: 10px 15px;
      background:#fff;
      border-radius: 10px;
      height: 60px;
      line-height: 40px;
      margin: 20px 0;
      box-shadow: 0px 10px 21px 1px rgba(0,0,0,0.05);
  }
  .product-selection ul a{
      font-size: 16px;
      font-weight: 500;
      color:#616161;
  }
  .product-selection ul a li span.search-title{
      padding-left:20px;
      white-space: nowrap;
      overflow: hidden !important;
      text-overflow: ellipsis;
      width: 167px;
      display: inline-block;
      vertical-align: middle;
  }
  .product-selection ul a li span img.booking-img{width:30px;}
  
  .single-services {
      box-shadow: 0px 7px 12px rgba(0, 0, 0, 0.2);
  }
  .single-services img{
      width:100%;
      height:180px;
      object-fit:cover;
  }
  .about-service a{
      background: transparent;
      color: var(--shop-theme1-primary-color);
      padding: 10px 0px;
      display: inline-block;
      border-radius: 50px;
      margin-top: 0px;
      font-weight: 600;
  }
  /* section banner area end */
  
  /* offer section */
  .slider{
      header:500px;
  }
  .slick-slider
  {
      position: relative;
  
      display: block;
      box-sizing: border-box;
  
      -webkit-user-select: none;
         -moz-user-select: none;
          -ms-user-select: none;
              user-select: none;
  
      -webkit-touch-callout: none;
      -khtml-user-select: none;
      -ms-touch-action: pan-y;
          touch-action: pan-y;
      -webkit-tap-highlight-color: transparent;
  }
  
  .slick-list
  {
      position: relative;
  
      display: block;
      overflow: hidden;
  
      margin: 0;
      padding: 0;
  }
  .slick-list:focus
  {
      outline: none;
  }
  .slick-list.dragging
  {
      cursor: pointer;
      cursor: hand;
  }
  
  .slick-slider .slick-track,
  .slick-slider .slick-list
  {
      -webkit-transform: translate3d(0, 0, 0);
         -moz-transform: translate3d(0, 0, 0);
          -ms-transform: translate3d(0, 0, 0);
           -o-transform: translate3d(0, 0, 0);
              transform: translate3d(0, 0, 0);
  }
  
  .slick-track
  {
      position: relative;
      top: 0;
      left: 0;
  
      display: block;
  }
  .slick-track:before,
  .slick-track:after
  {
      display: table;
  
      content: '';
  }
  .slick-track:after
  {
      clear: both;
  }
  .slick-loading .slick-track
  {
      visibility: hidden;
  }
  
  .slick-slide
  {
      display: none;
      float: left;
        width: 100%;
      height: 100%;
      min-height: 1px;
  }
  [dir='rtl'] .slick-slide
  {
      float: right;
  }
  .slick-slide img
  {
      display: block;
      border-radius: 15px;
  }
  .slick-slide.slick-loading img
  {
      display: none;
  }
  .slick-slide.dragging img
  {
      pointer-events: none;
  }
  .slick-initialized .slick-slide
  {
      display: block;
      margin: 15px;
  }
  .slick-loading .slick-slide
  {
      visibility: hidden;
  }
  .slick-vertical .slick-slide
  {
      display: block;
  
      height: auto;
  
      border: 1px solid transparent;
  }
  .slick-arrow.slick-hidden {
      display: none;
  }
  
  
  
  
  /* Slider */
  /* .slick-loading .slick-list
  {
      background: #fff url('./ajax-loader.gif') center center no-repeat;
  } */
  
  /* Icons */
  /* @font-face
  {
      font-family: 'slick';
      font-weight: normal;
      font-style: normal;
  
      src: url('./fonts/slick.eot');
      src: url('./fonts/slick.eot?#iefix') format('embedded-opentype'), url('./fonts/slick.woff') format('woff'), url('./fonts/slick.ttf') format('truetype'), url('./fonts/slick.svg#slick') format('svg');
  } */
  /* Arrows */
  .slick-prev,
  .slick-next
  {
      font-size: 0;
      line-height: 0;
      display:none !important;
      position: absolute;
      top: 50%;
  
      display: block;
  
      width: 20px;
      height: 20px;
      padding: 0;
      -webkit-transform: translate(0, -50%);
      -ms-transform: translate(0, -50%);
      transform: translate(0, -50%);
  
      cursor: pointer;
  
      color: transparent;
      border: none;
      outline: none;
      background: transparent;
      box-shadow: 0px 8px 12px -4px rgba(0,0,0,0.5);
      z-index:1;
  }
  .slick-prev:hover:before,
  .slick-prev:focus:before,
  .slick-next:hover:before,
  .slick-next:focus:before
  {
      opacity: 1;
  }
  .slick-prev.slick-disabled:before,
  .slick-next.slick-disabled:before
  {
      opacity: .25;
  }
  
  .slick-prev:before,
  .slick-next:before
  {
      font-family: 'slick';
      font-size: 20px;
      line-height: 1;
  
      opacity: .75;
      color: white;
  
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
  }
  
  .slick-prev
  {
      display: block;
      text-align: right;
      right: 0px;
      top: 30%;
      background: #fff;
      border-radius: 50%;
      width: 50px;
      height: 50px;
      line-height: 60px;
      text-align: center;
  }
  [dir='rtl'] .slick-prev
  {
      right: -25px;
      left: auto;
  }
  .slick-prev:before
  {
      content: 'Ã¢â€ ï¿½';
  }
  [dir='rtl'] .slick-prev:before
  {
      content: 'Ã¢â€ â€™';
  }
  
  .slick-next
  {
      right: 0px;
      background: #fff;
      border-radius: 50%;
      width: 50px;
      height: 50px;
      line-height: 60px;
      text-align: center;
      margin-top: 15px;
  
  top: 40%;
  }
  [dir='rtl'] .slick-next
  {
      right: auto;
      left: -25px;
  }
  .slick-next:before
  {
      content: 'Ã¢â€ â€™';
  }
  [dir='rtl'] .slick-next:before
  {
      content: 'Ã¢â€ ï¿½';
  }
  
  /* Dots */
  .slick-dotted.slick-slider
  {
      margin-bottom: 30px;
      padding: 0px;
  }
  
  .slick-dots
  {
      position: absolute;
      bottom: -30px;
      display: block;
      width: 100%;
      padding: 0;
      margin: 0;
      list-style: none;
      text-align: center;
  }
  .slick-dots li
  {
      position: relative;
  
      display: inline-block;
  
      width: 20px;
      height: 20px;
      margin: 0 5px;
      padding: 0;
  
      cursor: pointer;
  }
  .slick-dots li button
  {
      font-size: 0;
      line-height: 0;
  
      display: block;
  
      width: 20px;
      height: 20px;
      padding: 5px;
  
      cursor: pointer;
  
      color: transparent;
      border: 0;
      outline: none;
      background: transparent;
  }
  .slick-dots li button:hover,
  .slick-dots li button:focus
  {
      outline: none;
  }
  .slick-dots li button:hover:before,
  .slick-dots li button:focus:before
  {
      opacity: 1;
  }
  .slick-dots li button:before
  {
      font-family: 'slick';
      font-size: 50px;
      line-height: 20px;
  
      position: absolute;
      top: 0;
      left: 0;
  
      width: 20px;
      height: 20px;
  
      content: '\2022';
      text-align: center;
  
      opacity: .25;
      color: black;
  
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
  }
  .slick-active img{
      box-shadow: 0px 11px 12px 0px rgba(0,0,0,0.25);
      transform:scale(1.04);
  }
  
  .slick-dots li.slick-active button:before
  {
      opacity: 1;
      color: var(--shop-theme1-primary-color);
      transform:scale(1.04);
  }
  .offers .slick-dots{
      bottom: -90px;
  }
  .slider {
       width: 100%;
       margin: 0px auto;
       height:400px;
  }
  .slick-slide {
        margin: 0px 20px;
  }
  .slick-slide img {
     width: auto;
     object-fit: cover;
      margin-left: 15px;
  }
  .slick-prev:before,
  .slick-next:before {
     color: black;
  }
  
  .slick-carousel-468 .slick-slide {
       margin: 0 0px;
  }
  
  .slick-carousel-468 .slick-list {
       margin: 0 auto;
  }
  
  .slick-carousel-468 .slick-prev,
  .slick-carousel-468 .slick-next {
       margin-top: -66.6666666667px;
  }
  .slick-carousel-468 .slick-prev i.pe,
  .slick-carousel-468 .slick-next i.pe {
      font-size: 80px;
      color: #ffffff
  }
  .slick-carousel-468 .slick-prev .slick-counter {
              left: -80px;
              height: 80px;
              width: 80px;
              line-height: 76px;
          }
  
          .slick-carousel-468 .slick-next .slick-counter {
              right: -80px;
              height: 80px;
              width: 80px;
              line-height: 76px;
          }
  .slick-carousel-468 .slick-slide img{
      width: 100%;
      height:485px;
      object-fit: cover;
      margin-left: 0px;
      box-shadow: 0px 0px 0px 0px rgba(0,0,0,0.25);
      transform: scale(1);
      border-radius: 0px;
  }
  .slideshow {
    position: relative;
    z-index: 1;
    height: 100%;
    max-width: 100%;
    margin: 0px auto;
  }
  
  
    .slider-track {
      transition: all 1s cubic-bezier(0.7, 0, 0.3, 1);
    }
  
    .item {
      height: 100%;
      position: relative;
      z-index: 1;
    }
      .banner .slick-slide{
          height:auto !important;
      }
  .banner .slick-slide .slick-active img{
      box-shadow: 0px 0px 0px 0px rgba(0,0,0,0.25);
      transform: scale(1);
  }
  .banner .slick-list .slick-active .slick-slide .slick-active img{
      box-shadow: 0px 0px 0px 0px rgba(0,0,0,0.25);
      transform: scale(1);
  }
  .banner .slick-list .slick-slide img {
      width: 1400px;
      height: 450px;
      object-fit: cover;
      margin-left: 15px;
      box-shadow: 0px 0px 0px 0px rgba(0,0,0,0.25);
      margin-top:-5px;
  
  }
  .banner {
      width: 100%;
      margin-left: auto;
      margin-right: auto;
      max-width: initial;
  }
  
  .banner-text{
      position: absolute;
      z-index: 1;
      width: 30%;
      right: 60px;
      bottom: 220px;
      text-align: right;
  }
  .banner-text h2{
      font-weight: bold;
      color: #fff;
      text-shadow: 6px 8px 20px rgba(0, 0, 0, 0.4);
      line-height: 40px;
      white-space: nowrap; overflow: hidden !important; text-overflow: ellipsis;width: 410px;
  }
  
  .banner-text h3 {
      font-weight: bold;
      text-shadow: 6px 8px 20px rgba(0, 0, 0, 0.4);
      color:#fff;
      line-height: 40px;
      position:relative;
      top:0px;
      white-space: nowrap; overflow: hidden !important; text-overflow: ellipsis;width: 410px;
  }
  
  .banner-text p{
      color:#000;
      font-size:15px;
      text-shadow: 6px 8px 20px rgba(255, 255, 255, 0.4);
  }
  
  /*banner single search*/
  
  .theme1 .home_single_search h1{ font-size:36px; color:#000; line-height:1.3em; font-weight:400; margin:0; padding:0 0 20px;}
  .theme1 .home_single_search h1 span{ font-weight:600;}
  .theme1 .home_single_search p{ font-size:18px; line-height:1.8em; margin-bottom:42px;}
  .theme1 .home_single_search .postcodeform{ width:95%; float:left; border-radius:6px; }
  .theme1 .home_single_search .postcodeform input[type=text]{ height:60px; width:70%; float:left; border:none; border-radius:6px 0 0 6px; font-size:18px; padding:0 20px; position:relative; transition:all 200ms linear;}
  .theme1 .home_single_search .postcodeform input[type=text]:focus{ box-shadow:0 0 30px rgba(13,138,218,0.5);}
  .theme1 .home_single_search .postcodeform input[type=submit],.theme1 .home_single_search .postcodeform .btn{ height:60px; width:30%; float:left; border-radius:0 6px 6px 0; border:none; background:var(--shop-theme1-primary-color); color:#fff; text-transform:uppercase; font-size:20px; font-weight:600;}
  
  .custom-search-laundry{
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    transform: translate(-50%,-50%);
    z-index: 1;
  }
  
  .custom-search-laundry .single-search-blk {
    background-color: rgba(245, 245, 245, 0.86);
    box-shadow: 0px 11px 12px 0px rgba(0,0,0,0.25);
    height: 100%;
  }
  .single-search-blk h1 span {
    font-weight: 600;
  }
  .single-search-blk h1 {
    font-size: 36px;
    color: #000;
    line-height: 1.3em;
    font-weight: 400;
    margin: 0;
    padding: 0 0 20px;
  }
  .single-search-blk p {
    font-size: 18px;
    line-height: 1.8em;
    margin-bottom: 42px;
  }
  .single-search-blk .postcodeform {
    width: 95%;
    float: left;
    border-radius: 6px;
  }
  .single-search-blk .postcodeform input[type="text"] {
    height: 60px;
    width: 70%;
    float: left;
    border: none;
    border-radius: 6px 0 0 6px !important;
    font-size: 18px;
    padding: 0 20px;
    position: relative;
    transition: all 200ms linear;
  }
  .single-search-blk .postcodeform .btn {
    height: 60px;
    width: 30%;
    float: left;
    border-radius: 0 6px 6px 0;
    border: none;
    background: var(--shop-theme1-primary-color);
    color: #fff;
    text-transform: uppercase;
    font-size: 20px;
    font-weight: 600;
    margin-top: 0;
  }

  @media (min-width:1430px) and (max-width:1920px){
      .banner .slick-list .slick-slide img{
          width:1920px;
          height: 520px;
      }
  }
  
  section.offer-section{
      padding:15px 0;
  }
  .sec-offer strong{
      color:#09b2dc;
      font-size:26px;
      padding-right: 5px;
  }
  .sec-offer span a{
      font-size: 16px;
      font-weight: 500;
  }
  .sec-offer span a i{
      font-size: 16px;
      padding-left: 8px;
  }
  .product-selection ul a li span.proselection-right{
      text-align: right;
      font-size: 20px;
      padding-right: 4px;
  }
  /* offer section */
  
  /* about and service */
  .theme1 .price-section .list-inline-item.active a {
    background-color: var(--shop-theme1-primary-color);
  }
  .theme1 .section-title h1 {
    color: var(--shop-theme1-primary-color);
  }
  .theme1 .section-title span{
      margin-bottom:5px;
      font-size:13px;
  }
  .theme1 .section-title h2 span{
      color:var(--shop-theme1-primary-color);
      font-size: 2.8rem;
  }
  
  .theme1 .section-title h2::before {
      content: '';
      background: var(--shop-theme1-primary-color);
      width: 30px;
      height: 4px;
      position: absolute;
      left: 0;
      bottom: -1px;
      z-index: 1;
  }
  
  .theme1 .section-title h2::after {
      content: '';
      background: #ddd;
      width: 100px;
      height: 2px;
      position: absolute;
      left: 0;
      bottom: 0;
  }
  .about-service p{
      color: #868686;
      font-size: 16px;
      line-height: 25px;
  }
  .about-service h3{
      line-height: 40px;
      color:#002240;
  }
  .about-service{
      max-width: 75%;
  }
  .theme1 .section-title h3{
      font-size: 35px;
      margin-bottom:25px;
  }
  /* about and service */
  
  /* price section */
  
  .pricelist-slider .slick-prev:before, .pricelist-slider .slick-next:before,
  .pricelist-tab-slider .slick-prev:before, .pricelist-tab-slider .slick-next:before {
      color:#000000;
  }
  
  .pricelist-slider .slick-prev,
  .pricelist-slider .slick-next,
  .pricelist-slider .slick-prev:hover,
  .pricelist-slider .slick-prev:focus,
  .pricelist-slider .slick-next:hover,
  .pricelist-slider .slick-next:focus,
  .pricelist-tab-slider .slick-prev,
  .pricelist-tab-slider .slick-next,
  .pricelist-tab-slider .slick-prev:hover,
  .pricelist-tab-slider .slick-prev:focus,
  .pricelist-tab-slider .slick-next:hover,
  .pricelist-tab-slider .slick-next:focus {
      display:block !important;
      width:50px;
      height:50px;
      background:#ffffff;
  }
  
  .pricelist-slider .slick-next {margin-top:0px;}
  
  
  .pricelist-tab-slider .slick-prev { left:0px;}
  .pricelist-tab-slider .slick-next { right:0px; margin-top:0px;}
  .pricelist-tab-slider .slick-prev:before, .pricelist-tab-slider .slick-next:before { font-size:23px; font-weight:bolder; }
  
  .pricelist-tab-slider .slick-prev,
  .pricelist-tab-slider .slick-next,
  .pricelist-tab-slider .slick-prev:hover,
  .pricelist-tab-slider .slick-prev:focus,
  .pricelist-tab-slider .slick-next:hover,
  .pricelist-tab-slider .slick-next:focus {
      background:transparent;
      box-shadow:none;
  }
  
  .theme1 .price-section{
    background: #f0f0f0 url(../../../assets/img/shophome/price-background2.png);
      background-repeat: no-repeat;
      background-position: top right;
      background-repeat: no-repeat;
      padding: 80px 45px 0px;
  }
  
  
  
  .theme1 .price-section h2 {
      float: left;
      position: relative;
      left: 50%;
      transform: translateX(-50%);
  }
  
  .theme1 .price-tab-left .nav.nav-tabs {
      margin-right: 20px;
      border-bottom:0;
      border-right: 1px solid #ddd;
      padding-right: 15px;
  }
  .theme1 .price-tab-left .nav-tabs .nav-link {
      border: 1px solid transparent;
      border-top-left-radius: .25rem;
      border-top-right-radius: .25rem;
      background: transparent;
  }
  
  .theme1 .price-tab-left .nav-tabs .nav-link.active {
      color: #fff;
      background-color:var(--shop-theme1-primary-color);
      border-right: 0;
      border-top: 0;
      border-bottom: 0;
      border-left: 0px solid var(--shop-theme1-primary-color);
      box-shadow: 0px 10px 30px 0px rgba(244, 123, 99, 0.50);
      border-radius: 32px;
  }
  .theme1 .price-tab-left .nav-tabs .nav-link {
      border: 0px solid transparent;
      padding:14px 23px;
  }
  
  .theme1 .price-tab-left .nav-tabs .nav-link span {
      white-space: nowrap;
      overflow: hidden !important;
      text-overflow: ellipsis;
      width: 158px;
      display: inline-block;
      vertical-align: middle;
  }
  
  .theme1 .price-tab-left .nav.nav-tabs {
      width:95%;
      margin-right: 0px;
      border-bottom: 0;
      border-right: 0px solid transparent;
      padding-right: 0px;
      float: left;
      position: relative;
      left: 50%;
      transform: translateX(-50%);
      background: #fdebe7;
      border-radius: 50px;
      padding: 0px 50px;
  }
  .theme1 .price-tab-left{
      width:100%;
      float:left;
  }
  .theme1 .price-tab-left .nav.nav-tabs li.nav-item a img{
      padding-right: 7px;
  }
  
  
  .theme1 .price-tab-left ul li a img.pricelist-img{width:30px;margin-right:5px;}
  
  .theme1 .price-tab-right{
  
      position:relative;
      width: 100%;
      float: left;
      margin-top: -140px;
      min-height:500px;
      border-radius: 10px;
      bottom:-113px;
  }
  
  .theme1 .price-tab-right .slick-list{ padding-top:50px; }
  
  
  .theme1 .price-tab-right .slick-list .slick-track {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
  }
  
  
  .theme1 .price-tab-right .slick-track img { width:auto !important; display:inline-block !important; box-shadow:none !important; }
  
  
  
  .theme1 .pricelist-tab-slider .slick-track img { width:auto !important; display:inline-block !important; box-shadow:none !important; transform: none; }
  
  
  .best-dryclean-header{
      text-align: center;
      position:relative;
      height:auto;
  }
  .laundry-details{
      max-width: 99%;
      margin: 0px 0 20px;
      border: 1px solid #f0f0f0;
  }
  .best-dryclean-header p{
      position: absolute;
      font-size: 15px;
      font-weight: 600;
      padding: 14px 0px;
      top: 50%;
      width: 100%;
  }
  .best-dryclean-header img{
      width: 80px;
      border-radius: 100%;
      height: 80px;
      text-align: center;
      margin: 00% auto 0px;
      position: relative;
      background:#ffffff;
      box-shadow: -2px 15px 9px -3px rgba(0,0,0,0.25);
      top: -50%;
      transform: translateY(-50%);
  }
  
  .best-dryclean-header:before {
      position: absolute;
      content: "";
      background: #ffffff;
      width: 41%;
      height: 100%;
      left: 50%;
      top: -50%;
      transform: translateX(-50%);
      border-radius: 50%;
  }
  
  .drclean-header{
      background:#f0f0f0;
      position:relative;
      overflow:hidden;
  }
  
  .drclean-header a {
      position:absolute;
      z-index:50;
      top:0px;
      right:0px;
  }
  
  
  .drclean-header span{
      font-size:14px;
      font-weight: 600;
      white-space: nowrap;
      overflow: hidden !important;
      text-overflow: ellipsis;
      width: 104px;
      display: inline-block;
      vertical-align: middle;
  }
  
  .drclean-header .price-hrs { position:absolute; top:5px;right:5px;}
  .drclean-header .expand-collapse{ position:absolute; bottom:0px;right:0px; z-index:1; color:#ffffff; cursor:pointer;}
  .drclean-header .expand-collapse:before{ position:absolute; content:""; width:50px; height:50px; cursor:pointer; background:#d4d4d4; top:0px;right:0px;right: -23px;border-radius: 50%; z-index:-1;}
  
  .drclean-header .expand-collapse[data-toggle="collapse"]:after {
      font-family: 'FontAwesome';
      content: "\f106";
      /* "play" icon */
      float: right;
      color: #ffffff;
      font-size: 23px;
      line-height: 22px;
      position:relative;
      top:0px;
      right:3px;
      /* rotate "play" icon from > (right arrow) to down arrow */
  }
  
  .drclean-header .expand-collapse[data-toggle="collapse"].collapsed:after {
      /* rotate "play" icon from > (right arrow) to ^ (up arrow) */
      -webkit-transform: rotate(180deg);
      -moz-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
      -o-transform: rotate(180deg);
      transform: rotate(180deg);
      top:5px;
  }
  
  
  .drclean-header span img{
      width: 25px;
      border-radius: 50%;
      margin-right: 5px;
      height: 25px;
  }
  
  .drclean-body-one{
      margin: 7px 10px 0 0;
  }
  .drclean-body-one span{
      font-size:13px;
      color:#7a7a7a;
  }
  .drclean-body-two{
      margin:7px 10px 0 0;
  }
  .drclean-body-two span{
      font-size:13px;
      color:#000;
  }
  
  .drclean-body{
      padding: 0px 15px 10px;
  }
  
  
  .best-drclean {
      border:1px solid #555555; border-radius:41px; padding:0px 10px;
      max-height: 500px;
      }
  
  .price-wrapper {
      width: 100%;
      float: left;
      background: #ffffff;
      margin-top: -104px !important;
      /*min-height: 680px;*/
      border-radius: 10px;
      bottom: -113px;
      box-shadow: 0px 10px 21px 1px rgba(0,0,0,0.15);
      padding: 32px 0px;
      position: relative;
  }
  
  
  /* price section */
  
  /* offer section */
  .offers-list-banner{margin:30px 0;}
  .swiperwrap{height:250px !important;}
  .offers-list-banner a img{
      box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.2);
  }
  .swiper-pagination{position: relative;top: 25px;}
  .offers .slick-slide img{
      width: 90%;
      height: auto !important;
      transform: scale(1);
      margin-left:0px;
  }
  /* offer section */
  
  .carousel-item  img{
      height:400px;
      object-fit:cover;
  }
  .swipperwrap{
      height:250px !important;
  }
  /* footer section */
  .theme1 .app-section-title h2{
      color:#fff;
  }
  .theme1 .footer-section{
      color:#fff;
      position:relative;
      background:var(--shop-theme1-primary-color) url(../../../assets/img/shophome/price-background1.png) no-repeat;
      background-position: -3% 98%;
      margin-top:140px;
  }
  .appdownload-img{
      text-align:center;
      margin:0 auto;
      position:absolute;
      top:110px;
      width:100%;
  }
  .appdownload-img img{
      animation-name: mock-animation;
      animation-duration: 2s;
      animation-iteration-count: infinite;
      animation-timing-function: ease-in-out;
      -webkit-animation-name: mock-animation;
      -webkit-animation-duration: 2s;
      -webkit-animation-iteration-count: infinite;
      -webkit-animation-timing-function: linear;
  }
  /*Mockup Animation*/
   @-webkit-keyframes mock-animation {
       0% {
           -webkit-transform: translateY(-20px);
           transform: translateY(-20px);
      }
       50% {
           -webkit-transform: translateY(-10px);
           transform: translateY(-10px);
      }
       100% {
           -webkit-transform: translateY(-25px);
           transform: translateY(-25px);
      }
  }
   @keyframes mock-animation {
       0% {
           -webkit-transform: translateY(-20px);
           transform: translateY(-20px);
      }
       50% {
           -webkit-transform: translateY(-10px);
           transform: translateY(-10px);
      }
       100% {
           -webkit-transform: translateY(-20px);
           transform: translateY(-20px);
      }
  }
  .hvr-radial-out {
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    position: relative;
    overflow: hidden;
    background: #e1e1e1;
    -webkit-transition-property: color;
    transition-property: color;
    -webkit-transition-duration: 0.1s;
    transition-duration: 0.1s;
  }
  .hvr-radial-out:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #2098D1;
    border-radius: 100%;
    -webkit-transform: scale(0);
    transform: scale(0);
    -webkit-transition-property: transform;
    transition-property: transform;
    -webkit-transition-duration: 0.1s;
    transition-duration: 0.1s;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
  }
  .hvr-radial-out:hover, .hvr-radial-out:focus, .hvr-radial-out:active {
    color: white;
  }
  .hvr-radial-out:hover:before, .hvr-radial-out:focus:before, .hvr-radial-out:active:before {
    -webkit-transform: scale(2);
    transform: scale(2);
  }
  
  .footer-background{
      background: #fff url(../../../assets/img/shophome/footerbackground.png);
      background-repeat: no-repeat;
      background-position: -20% 40%;
      background-repeat: no-repeat;
      background-size: cover;
      width: 100%;
      height: 290px;
  }
  .blue-theme .footer-background {
    background: var(--shop-theme1-primary-color)
  }
  .footer-section p{
      font-size:14px;
  }
  .hr-footer{
      border-color:#969696;
      max-width:40%;
      padding-top: 0px !important;
      margin-bottom: 0px !important;
      margin: 0 auto;
  }
  
  ul.social-network {
      margin-top: 15px;
      list-style: none;
      display: inline;
      padding: 0;
  }
  ul.social-network li {
      display: inline;
      margin: 0 5px;
  }
  .social-network a.icoRss {
      background-color: #F56505;
  }
  .social-network a.icoRss:hover {
      background-color: #F56505;
  }
  .social-network a.icoFacebook {
      background-color:#3B5998;
  }
  .social-network a.icoFacebook:hover {
      background-color:#3B5998;
  }
  .social-network a.icoTwitter {
      background-color:#33ccff;
  }
  .social-network a.icoTwitter:hover {
      background-color:#33ccff;
  }
  .social-network a.icoGoogle {
      background-color:#BD3518;
  }
  .social-network a.icoGoogle:hover {
      background-color:#BD3518;
  }
  .social-network a.icoVimeo {
      background-color:#0590B8;
  }
  .social-network a.icoVimeo:hover {
      background-color:#0590B8;
  }
  .social-network a.icoLinkedin {
      background-color:#007bb7;
  }
  .social-network a.icoLinkedin:hover {
      background-color:#007bb7;
  }
  .social-network a.icoRss:hover i, .social-network a.icoFacebook:hover i, .social-network a.icoTwitter:hover i,
  .social-network a.icoGoogle:hover i, .social-network a.icoVimeo:hover i, .social-network a.icoLinkedin:hover i {
      color:#fff;
  }
  a.socialIcon:hover, .socialHoverClass {
      color:#44BCDD;
  }
  
  .social-circle li a {
      display:inline-block;
      position:relative;
      margin:0 auto 0 auto;
      -moz-border-radius:50%;
      -webkit-border-radius:50%;
      border-radius:50%;
      text-align:center;
      width: 50px;
      height: 50px;
      font-size:20px;
  }
  .social-circle li i {
      margin:0;
      line-height:50px;
      text-align: center;
  }
  
  .social-circle li a:hover i, .triggeredHover {
      -moz-transform: rotate(360deg);
      -webkit-transform: rotate(360deg);
      -ms--transform: rotate(360deg);
      transform: rotate(360deg);
      -webkit-transition: all 0.2s;
      -moz-transition: all 0.2s;
      -o-transition: all 0.2s;
      -ms-transition: all 0.2s;
      transition: all 0.2s;
  }
  .social-circle i {
      color: #fff;
      -webkit-transition: all 0.8s;
      -moz-transition: all 0.8s;
      -o-transition: all 0.8s;
      -ms-transition: all 0.8s;
      transition: all 0.8s;
  }
  .footer-first-section{
      padding:0rem 3rem 1rem 3rem;
  }
  .footer-first-section h5, .footer-first-section a{
      font-size:13px;
      color:#fff;
  }
  .footer-first-section h6{
      text-align: center;
      font-size: 12px;
      color: #fff;
      font-weight: 100;
      padding: 10px 0px;
  }
  .showhide-footer i{
      background: #f7f7f7;
      color: #000;
      width: 50px;
      height: 50px;
      line-height: 50px;
      border-radius: 50%;
      font-size: 21px;
      position: relative;
      top: -20px;
      cursor:pointer;
      transition: all 0.2s ease-in-out;
      -webkit-transition: all 0.2s ease-in-out;
      -moz-transition: all 0.2s ease-in-out;
      -o-transition: all 0.2s ease-in-out;
      -ms-transition: all 0.2s ease-in-out;
  }
  
  /* footer section */
  
  .navbar-trans img.shop-logo{
      position: absolute;
      z-index: 2;
      width: 85px;
      top: 0px;
      transition: all 0.2s ease-in;
      -webkit-transition: all 0.2s ease-in;
      -moz-transition: all 0.2s ease-in;
      -o-transition: all 0.2s ease-in;
      -ms-transition: all 0.2s ease-in;
  }
  .top-nav-bar{
      position:relative;
  }
  .navbar-trans img.shop-logo-txt{
      position: absolute;
      left:110px;
      top: -5px;
  }
  .navbar-default.navbar-reduce img.shop-logo{
      width:50px;
      top:-6px;
  }
  .top-nav-bar .shop-logo-txt {
      left: 110px;
      top: 0px;
      position: absolute;
  }
  .offer-details{
      margin: 20px 0;
      padding: 0px 55px 0px 105px;
  }
  .navbar-default.navbar-reduce .shop-logo-txt{
      left: 75px;
      top: -5px;
  }
  
  
  /*sticky bottom*/
  
  .sticky-bottom {
      transform: translateY(100%);
  }
  .sticky-show {
      transform: translateY(0px);
  }
  .sticky-bottom {
      background: rgba(244, 123, 99,0.86);
      bottom: 0;
      color: #ffffff;
      position: fixed;
      width: 100%;
      padding:7px 0px;
      transition: transform 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
      z-index: 9999;
  }
  
  .sticky-bottom p, .sticky-bottom a { margin-bottom: 0px; font-size: 18px; color: #ffffff; }
  .sticky-bottom a i { font-size: 32px; color: #ffffff; vertical-align: middle; }
  
  @media only screen and (max-width:1199px){
  
  
  .theme1 .home_single_search h1{ font-size:30px; }
  .theme1 .home_single_search h1 span{ font-weight:600;}
  .theme1 .home_single_search p{ font-size:16px; line-height:1.4em; margin-bottom:23px;}
  
  
  
    .banner-text h2 {
      width:320px;
    }
  
      .slick-initialized .slick-slide {
          margin:5px 15px;
          padding:5px 0px !important;
      }
  
      .price-tab-left .nav-tabs .nav-link {
          font-size:14px;
          padding:14px;
      }
  
      .price-tab-right .slick-list .slick-track {
          display: block !important;
      }
  
      .price-tab-left .nav-tabs .nav-link
      {
          font-size:12px;
          padding:7px 10px;
      }
      .footer-background{
          height:200px;
      }
      .appdownload-img{
          top:60px;
      }
      .banner-text, .offsec-hedaer{
          right:55px;
      }
  }
  @media only screen and (max-width:991px){
  
  
  
    .theme1 .home_single_search p {
      font-size: 16px;
  }
  
  .theme1 .home_single_search h1 {
      font-size: 21px;
  }
  
  
  
    .banner-text h2 {
      width:257px;
    }
  
  
      .section-banner{
          background-position:60% 0%;
      }
      .footer-background{
          height:170px;
      }
      .appdownload-img{
          top:30px;
      }
      .price-tab-left .nav-tabs .nav-link{
          font-size: 13px;
          padding: 5px;
      }
      .nav-tabs-rounded{
          float:left !important;
      }
      .swiper-pagination-bullet{
          margin-right:5px;
      }
      .banner .slick-list .slick-slide img{
          width:1000px;
      }
      .banner-text, .offsec-hedaer{
          right:60px;
      }
      .offers .slick-slide img {
          width: auto;
          height: 250px !Important;
      }
      .slider{
          height:200px;
      }
      .slick-carousel-468 .slick-slide img{
          margin-left:0px;
      }
      .variable .slick-slide img {
          width: auto;
          object-fit: cover;
          margin-left: 15px;
          height: 250px;
      }
      .navbar-default.navbar-reduce img.shop-logo-txt {
          left: auto;
          right: 60px;
      }
      .navbar-trans img.shop-logo-txt {
          left: auto;
          right: 90px;
      }
      .navbar-trans img.shop-logo{
          right:0px;
      }
      .top-nav-bar .shop-logo-txt {
          left: auto;
          top: -3px;
          right: 100px;
      }
      .navbar-default.navbar-reduce .shop-logo-txt{
          left: auto;
          right: 65px;
      }
  }
  @media only screen and (max-width:767px){
      .navbar-default{
          padding: 10px 15px 10px 15px;
      }
      .navbar-brand{
          text-align:center;
          margin:0;
      }
      .theme1 section {
          padding: 10px 15px;
      }
      .navbar-nav{
          float: left !important;
          padding: 15px;
      }
      .section-banner{
          background-position: 65% 0%;
      }
      .offsec-hedaer{
          right: 0px;
          bottom: 0px;
          text-align: center;
          left: 0px;
          margin: 0 auto;
          position:static;
      }
      .about-section{
          padding: 100px 0;
      }
      .custom-search-laundry{
          width:100%;
          margin:0 auto;
          left:0px;
          top: 0px;
          position:static;
      }
      .theme1 .price-section{
          padding:80px 15px;
      }
      .price-tab-left .nav.nav-tabs{
          float:left !important;
      }
      .drclean-header{
          text-align:center;
      }
      .drclean-header span.price-hrs{
          float: none !important;
          display: block;
          margin: 5px 0px;
      }
      .footer-background {
          background-position: 0% 0%;
          height: 190px;
      }
      .appdownload-img{
          top:60px;
      }
      .footer-section{
          background: none;
          background-color:var(--shop-theme1-primary-color);
          margin-top:-70px;
      }
      .banner .slick-list .slick-slide img {
          width: 800px;
          height: 260px;
      }
      .section-banner{
          height:auto;
      }
      .slick-dots{
          bottom: 30px;
      }
      .slick-carousel-468 .slick-slide img {
          width: 100%;
          height: auto;
          /* object-fit: cover; */
          margin-left: 0px;
          box-shadow: 0px 0px 0px 0px rgba(0,0,0,0.25);
          transform: scale(1);
          border-radius:0px;
      }
      .offers .slick-slide img {
          width: auto;
          height: 200px !important;
      }
      .offers .slick-dots {
          bottom: 30px;
      }
      .slick-carousel-468{
          height:auto;
      }
      .slick-carousel-468 .slick-list{
          height:auto !important;
      }
      .variable .slick-slide img {
          width: auto;
          object-fit: cover;
          margin-left: 15px;
          height: 250px;
      }
  
      .xs-mt-32 {
          margin-top:32px;
      }
  
      .xs-only {
      display: block;
      margin-bottom: 23px;
      text-align: center;
      }
  
  
  
      .theme1 .home_single_search .postcodeform input[type="submit"],.theme1 .home_single_search .postcodeform .btn {
      height: 55px;
      font-size: 16px;
  }
  
  .custom-search-laundry {
      position: relative;
      top: auto;
      left: auto;
      transform: translate(0%,0%);
      z-index: 1;
  }
  
  .custom-search-laundry .single-search-blk {
    padding:14px;
  }
  
  }
  @media only screen and (max-width:479px){
  
    .theme1 .home_single_search .postcodeform input[type="text"] {
      width: 60%;
  }
  
  
  .theme1 .home_single_search .postcodeform {
      width: 100%;
  }
  
  .theme1 .home_single_search .postcodeform input[type="text"] {
      height: 55px;
      padding: 0 15px;
      font-size: 16px;
  }
  
  .theme1 .home_single_search .postcodeform input[type="submit"],.theme1 .home_single_search .postcodeform .btn {
      width: 40%;
  }
  
      .appdownload-img{
          top:80px;
      }
      .price-tab-left ul li {
          display: block;
      }
      .footer-first-section {
          padding: 0rem 0rem 1rem 0rem;
      }
      .product-selection ul a li span.search-title {
          padding-left: 10px;
      }
      .offers .slick-slide img {
          width: auto;
          height: 140px !important;
      }
      .text-brand img{
          max-width:230px;
      }
      .variable .slick-slide img {
          width: auto;
          object-fit: cover;
          margin-left: 15px;
          height: 200px;
      }
  }
  
  /* Customized CSS */
  .location-search .has-error {
      border: 1px solid red;
  }
  
  .hidden {
      display: none;
  }
  
  /* How we work section - starts */
  
  #lottie{
      background-color:#fff;
      width:100%;
      height:100%;
      display:block;
      overflow: hidden;
      transform: translate3d(0,0,0);
      text-align: center;
      opacity: 1;
  }
  
  .how-we-work-container {
      padding: 0px;
  }
  
  /* How we work section - ends */
  .panel-group .panel+.panel {
      margin-top: 5px;
  }
  
  .panel-group .panel {
      margin-bottom: 0;
      border-radius: 4px;
  }
  
  .panel-group {
      margin-bottom: 0px;
  }
  
  .panel-group .panel {
      margin-bottom: 0;
      border-radius: 4px;
      border-color: #ddd;
  }
  
  .panel-default {
      border-color: #ddd;
  }
  
  .panel {
      margin-bottom: 20px;
      background-color: #fff;
      border: 1px solid transparent;
      border-radius: 4px;
      -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, .05);
      box-shadow: 0 1px 1px rgba(0, 0, 0, .05);
  }
  
  .panel-default>.panel-heading {
      color: #333;
      background-color: #f5f5f5;
      border-color: #ddd;
  }
  
  .panel-group .panel-heading {
      border-bottom: 0;
  }
  
  .panel-heading {
      padding: 10px 15px;
      border-bottom: 1px solid transparent;
      border-top-left-radius: 3px;
      border-top-right-radius: 3px;
  }
  
  .panel-title {
      font-size: 1rem;
      margin-bottom: 0px;
  }
  
  .panel-title {
      font-size: 1rem;
      margin-bottom: 0px;
  }
  
  .price-list-items .list-inline, .price-list-items .list-inline a {
      margin-bottom: 0px;
  }
  
  .price-list-details .panel-heading { padding:23px 14px; }
  .price-list-details .panel-body { padding:0px 10px; }
  .price-list-details .list-group { margin-bottom: 0px; }
  
  .price-list-details .panel-title > a.arrow-style:before {
      font-size: 32px;
      line-height: 19px;
      float: right !important;
      font-family: FontAwesome;
      font-weight: normal;
      content:"\f106";
      padding-right: 5px;
  }
  
  
  .price-list-details .panel-title .custom-truncate {
      display: inline-block;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      /*width: 410px;*/
  }
  
  .theme1 .price-section .panel-group {
    margin-bottom: 10px;
  }
  
  .price-list-details .panel-title > a.arrow-style.collapsed:before {
      float: right !important;
      content:"\f107";
      font-weight: normal;
      line-height: 14px;
  }
  
  .price-list-details .panel-title span {
    margin-right: 10px;
    vertical-align: middle; }
  
  .price-list-details > a:hover,
  .price-list-details > a:active,
  .price-list-details > a:focus  {
      text-decoration:none;
  }
  